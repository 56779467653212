import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _52b295c4 = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _7536985c = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _4fd934bb = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _24e87744 = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _84d084fe = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _1199b56f = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _2c7e923e = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _76d551a0 = () => interopDefault(import('../src/pages/select-extras/index.vue' /* webpackChunkName: "pages/select-extras/index" */))
const _d6c4060a = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _67aa7bff = () => interopDefault(import('../src/pages/travel-extras.vue' /* webpackChunkName: "pages/travel-extras" */))
const _3ed58959 = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _6239a5a4 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _6ccd13be = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _26760ae1 = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _4017dcb6 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _4c0ffe80 = () => interopDefault(import('../src/pages/select-extras/fasttrack/index.vue' /* webpackChunkName: "pages/select-extras/fasttrack/index" */))
const _7628b78a = () => interopDefault(import('../src/pages/select-extras/lounges/index.vue' /* webpackChunkName: "pages/select-extras/lounges/index" */))
const _00632e80 = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _227de58a = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _09f1e14a = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _52b295c4,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _7536985c,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _4fd934bb,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _24e87744,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _84d084fe,
    name: "receipt"
  }, {
    path: "/search",
    component: _1199b56f,
    name: "search"
  }, {
    path: "/search-booking",
    component: _2c7e923e,
    name: "search-booking"
  }, {
    path: "/select-extras",
    component: _76d551a0,
    name: "select-extras"
  }, {
    path: "/terms-and-conditions",
    component: _d6c4060a,
    name: "terms-and-conditions"
  }, {
    path: "/travel-extras",
    component: _67aa7bff,
    name: "travel-extras"
  }, {
    path: "/unsupported-browser",
    component: _3ed58959,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _6239a5a4,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _6ccd13be,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _26760ae1,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _4017dcb6,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/fasttrack",
    component: _4c0ffe80,
    name: "select-extras-fasttrack"
  }, {
    path: "/select-extras/lounges",
    component: _7628b78a,
    name: "select-extras-lounges"
  }, {
    path: "/select-extras/product",
    component: _00632e80,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _227de58a,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _09f1e14a,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
